export const ORDENAR_OPCOES = [
  {
    uuid: "distancia",
    nome: "Menor distância"
  },
  {
    uuid: "total_uniformes",
    nome: "Menor preço"
  },
  {
    uuid: "nome_fantasia",
    nome: "Ordem alfabética"
  }
];
