export const ESTADOS = [
  { nome_extenso: "Acre", nome: "AC" },
  { nome_extenso: "Alagoas", nome: "AL" },
  { nome_extenso: "Amapá", nome: "AP" },
  { nome_extenso: "Amazonas", nome: "AM" },
  { nome_extenso: "Bahia", nome: "BA" },
  { nome_extenso: "Ceará", nome: "CE" },
  { nome_extenso: "Distrito Federal", nome: "DF" },
  { nome_extenso: "Espírito Santo", nome: "ES" },
  { nome_extenso: "Goiás", nome: "GO" },
  { nome_extenso: "Maranhão", nome: "MA" },
  { nome_extenso: "Mato Grosso", nome: "MT" },
  { nome_extenso: "Mato Grosso do Sul", nome: "MS" },
  { nome_extenso: "Minas Gerais", nome: "MG" },
  { nome_extenso: "Pará", nome: "PA" },
  { nome_extenso: "Paraíba", nome: "PB" },
  { nome_extenso: "Paraná", nome: "PR" },
  { nome_extenso: "Pernambuco", nome: "PE" },
  { nome_extenso: "Piauí", nome: "PI" },
  { nome_extenso: "Rio de Janeiro", nome: "RJ" },
  { nome_extenso: "Rio Grande do Norte", nome: "RN" },
  { nome_extenso: "Rio Grande do Sul", nome: "RS" },
  { nome_extenso: "Rondônia", nome: "RO" },
  { nome_extenso: "Roraima", nome: "RR" },
  { nome_extenso: "Santa Catarina", nome: "SC" },
  { nome_extenso: "São Paulo", nome: "SP" },
  { nome_extenso: "Sergipe", nome: "SE" },
  { nome_extenso: "Tocantins", nome: "TO" },
];
